@use "@causevest/ui-kit/styles";

.shadowBlock {
  background: styles.$color-white;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 10px 20px 0 rgba(5, 29, 60, 0.03);
}

.badge {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 6px 12px;
  color: styles.$color-white;
  font-size: 16px;
  background: styles.$color-blue;
  border-radius: 4px 0 0 4px;

  &._mobileBadge {
    display: none;
  }
}

.pageWrapper {
  position: relative;
  font-size: 14px;
  line-height: 1;
  min-height: calc(100vh - 410px);
  background: styles.$color-bg-blue;
}

.input {
  width: 100%;

  [role="combobox"] {
    padding: 10px 15px;
  }

  &._last {
    margin-top: 10px;
  }

  input {
    padding: 10px 15px;
  }
}

.submitBtn {
  width: 145px;
  height: 35px;
}

.viewMoreBtn {
  display: block;
  width: 100%;
  text-align: center;
  color: styles.$color-blue;
  font-size: 14px;
  font-weight: 500;
}

.barFlowerBg {
  position: absolute;
  right: -50px;
  bottom: -33px;
  background: url("/images/bg-octagon.png") center center no-repeat;
  width: 100px;
  height: 100px;

  > * {
    right: 35px;
    bottom: 35px;
  }
}

@media (max-width: styles.$md) {
  .badge {
    display: none;
    position: static;
    border-radius: 4px;

    &._mobileBadge {
      display: block;
    }
  }
}

@media (max-width: 590px) {
  .barFlowerBg {
    display: none;
  }
}

@media (max-width: styles.$sm) {
  .badge {
    font-size: 14px;
  }
}
